import React, { memo } from 'react';
import { Button } from 'react-bootstrap';

export default memo(() => {
    return (
        <div className="containerOne" id="widgets">
            <div className="row">
                <div className="col-md-12 text-center mt-3 mb-5">
                    <h1>
                        <strong>Pricing</strong>
                    </h1>
                    <h5>Studies have shown that gain. Average 40%/man-day.</h5>
                </div>
                <div className="col-md-12  mb-5 ">
                    <div className=" pricing">
                        <div className=" pricingBlock">
                            <div className="title">
                                <h3>
                                    <strong>Ücretsiz</strong>
                                </h3>
                            </div>
                            <div className="content">
                                <ul>
                                    <li>Sınırlı kod erişimi</li>
                                    <li>Dökümantasyon ve paket erişimi</li>
                                    <li>1 Kullanıcı</li>
                                </ul>
                            </div>
                            <div className="price mb-5">
                                <h3 className="mb-4">
                                    <strong>30.000</strong>
                                </h3>
                                <Button variant="danger">Start</Button>
                            </div>
                        </div>

                        <div className=" pricingBlock">
                            <div className="title">
                                <h3>
                                    <strong>Giriş</strong>
                                </h3>
                            </div>
                            <div className="content">
                                <ul>
                                    <li>Sınırlı kod erişimi</li>
                                    <li>Dökümantasyon ve paket erişimi</li>
                                    <li>1 Kullanıcı</li>
                                </ul>
                            </div>
                            <div className="price mb-5">
                                <h3 className="mb-4">
                                    <strong>30.000</strong>
                                </h3>
                                <Button variant="danger">Start</Button>
                            </div>
                        </div>

                        <div className=" pricingBlock">
                            <div className="title">
                                <h3>
                                    <strong>Profesyonel</strong>
                                </h3>
                            </div>
                            <div className="content">
                                <ul>
                                    <li>Sınırlı kod erişimi</li>
                                    <li>Dökümantasyon ve paket erişimi</li>
                                    <li>1 Kullanıcı</li>
                                </ul>
                            </div>
                            <div className="price mb-5">
                                <h3 className="mb-4">
                                    <strong>30.000</strong>
                                </h3>
                                <Button variant="danger">Start</Button>
                            </div>
                        </div>

                        <div className=" pricingBlock">
                            <div className="title">
                                <h3>
                                    <strong>Kurumsal</strong>
                                </h3>
                            </div>
                            <div className="content">
                                <ul>
                                    <li>Sınırlı kod erişimi</li>
                                    <li>Dökümantasyon ve paket erişimi</li>
                                    <li>1 Kullanıcı</li>
                                </ul>
                            </div>
                            <div className="price mb-5">
                                <h3 className="mb-4">
                                    <strong>30.000</strong>
                                </h3>
                                <Button variant="danger">Start</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

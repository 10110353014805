import React, { memo } from 'react';

export default memo((prop:any) => {
    return (
        <div  id="widgets">
            <div className="row ">
             
                <div className="col-md-12">
                    <div className="overviewFeatures">
                        <div className="row">
                            <div className="col-md-12 p-0">
                            <div className="title"  style={{backgroundColor: prop.backgroundColor}}>
                                    <h5>
                                        <strong>Services</strong>
                                    </h5>
                                </div>
                            </div>
                            <div className="col-md-12  tabContainer" style={{backgroundColor: prop.backgroundColor}}>
                                <div className="row">
                                    <div className="col-md-3 mt-3">
                                        <div className="chips">
                                            <h6>Document Management Service</h6>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-3">
                                        <div className="chips">
                                            <h6>Rule Engine</h6>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-3">
                                        <div className="chips">
                                            <h6>Notification Service</h6>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-3">
                                        <div className="chips">
                                            <h6>Identity & Access Management</h6>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="chips">
                                            <h6>Chatbot Service</h6>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="chips">
                                            <h6>API Gateway</h6>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="chips">
                                            <h6>Workflow Engine</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
});
import React, { memo } from 'react';

export default memo((prop:any) => {
    return (
        <div id="widgets">
            <div className="row ">
                <div className="col-md-12">
                    <div className="overviewFeatures">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className="title"  style={{backgroundColor: prop.backgroundColor}}>
                                    <h5>
                                        <strong>Templates</strong>
                                    </h5>
                                </div>
                            </div>
                            <div className="col-md-12  tabContainer" style={{backgroundColor: prop.backgroundColor}}>
                                <div className="row">
                                    <div className="col-lg-2 col-md-3 col-sm-3 mb-2 mt-3">
                                        <div className="chips">
                                            <h6>ASP.NET / MVC</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-3 mb-2 mt-3">
                                        <div className="chips">
                                            <h6>React.js</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-3 mb-2 mt-3">
                                        <div className="chips">
                                            <h6>Vue.js</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-3 mb-2 mt-3">
                                        <div className="chips">
                                            <h6>.Net Core Web Api</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-3 col-sm-3 mb-2 mt-3">
                                        <div className="chips">
                                            <h6>IOS</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-3 mb-2 mt-3">
                                        <div className="chips">
                                            <h6>Android</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-3 mb-2 mt-3">
                                        <div className="chips">
                                            <h6>Flutter</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-3 mb-2 mt-3">
                                        <div className="chips">
                                            <h6>Hangfire</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

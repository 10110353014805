import React, { memo } from 'react';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default memo(() => {
    return (
        <div id="widgets">
            <div className="containerOne">
                <div className="link-containerOne"></div>
                <div className="row">
                    <div className="col-lg-6">
                        <h3 className="link-title">OneFrame® UI Components</h3>
                        <div className="card-deck mb-3 text-center">
                            <div className="card mb-4 box-shadow blockColor">
                                <div className="card-body">
                                    <h1>
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </h1>
                                    <h5>React </h5>
                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly</p>
                                </div>
                            </div>

                            <div className="card mb-4 box-shadow blockColor">
                                <div className="card-body">
                                    <h1>
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </h1>
                                    <h5>Javascript </h5>
                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <h3 className="link-title">OneFrame® Templates</h3>
                        <div className="card-deck mb-3 text-center">
                            <div className="card mb-4 box-shadow blockColor">
                                <div className="card-body">
                                    <h1>
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </h1>
                                    <h5>MVC </h5>
                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly</p>
                                </div>
                            </div>

                            <div className="card mb-4 box-shadow blockColor">
                                <div className="card-body">
                                    <h1>
                                        <FontAwesomeIcon icon={faGlobe} />
                                    </h1>
                                    <h5>React (SPA) </h5>
                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

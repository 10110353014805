import React, { memo } from 'react';
import oneframeFirst from './../../assets/img/banner/oneframe-first.png';
import oneframeSecond from './../../assets/img/banner/oneframe-second.png';
import { NavLink } from 'react-router-dom';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

export default memo(() => {
    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const slider = (
        <div id="banner">
            <AutoplaySlider
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={6000}
            >
                <div className="containerOne">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="banner-text">
                                <h3>OneFrame®</h3>
                                <h1>
                                    Rapid application development platform for all your technology & business needs.{' '}
                                </h1>
                                <p>
                                    OneFrame® is a rapid and powerful enterprise application development platform that
                                    supports various modern application types. You can choose from a wide variety of
                                    templates like a .NET Core Web API or a native IOS template to create your app in an
                                    instant.
                                </p>
                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <NavLink exact={true} activeClassName="active" to="/support-packages">
                                            <button className="btn btn-danger btn-lg w-100 ">Get Started</button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="banner-image">
                                <img src={oneframeFirst} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="containerOne">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="banner-text">
                                <h3>OneFrame®</h3>
                                <h1>OneFrame Application Development Platform</h1>
                                <p>
                                    OneFrame Application Development Platform helps you quickly and easily develop UIs,
                                    add features, and fix bugs faster.
                                </p>
                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <NavLink exact={true} activeClassName="active" to="/support-packages">
                                            <button id="menuButton" className="btn btn-danger btn-lg w-100 ">
                                                Get Started
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="banner-image">
                                <img src={oneframeSecond} />
                            </div>
                        </div>
                    </div>
                </div>
            </AutoplaySlider>
        </div>
    );

    return slider;
});

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

const container = (
        <App />
);

const documentContainer: any = document.getElementById('root');
ReactDOM.render(container, documentContainer);
serviceWorker.unregister();

import React from 'react';
import { Content } from './../../comp';
const ContactUs = () => {
    return (
        <>
            <Content
                content={
                    <div id="page">
                        <div className="pageBanner"></div>
                        <div className="containerOne">
                            <div id="contact" className="contex m-0">
                                <div className="row">
                                    <div className="col-md-12 text-center mt-3 mb-5">
                                        <div className="ad-title">
                                            <h3>Address</h3>
                                        </div>
                                        <div className="title">
                                            <h1>Contact Us</h1>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="left">
                                            <div className="ad-title">
                                                <h3>Center</h3>
                                            </div>
                                            <div className="title">
                                                <h1>KoçSistem Head Quarters</h1>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="address">
                                                <h5>Address</h5>
                                                <p>
                                                    KoçSistem Bilgi ve İletişim Hizmetleri A.Ş Ünalan Mahallesi Çağla
                                                    Sokak Çamlıca İş Merkezi Üsküdar / İstanbul{' '}
                                                </p>
                                                <h5>E-Mail</h5>
                                                <p>ghostbusters@kocsistem.com.tr </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default ContactUs;

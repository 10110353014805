import React, { memo } from 'react';
import img1 from './../../../assets/img/react.png';
import img2 from './../../../assets/img/2.png';
import img3 from './../../../assets/img/3.png';
import img4 from './../../../assets/img/4.png';
import img5 from './../../../assets/img/5.png';
import img6 from './../../../assets/img/6.png';
import img7 from './../../../assets/img/7.png';
import img8 from './../../../assets/img/8.png';

export default memo(() => {
    return (
        <div id="widgets">
            <div className="containerOne logos text-center">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="title">
                            <h1>
                                <strong>Client</strong>
                            </h1>
                            <h3>if your question is not answered above, we're happy to help.</h3>
                            <p className="mt-1">
                            OneFrame® hakkında detaylı bilgi almak için{' '}
                                <a href="">
                                    <strong>iletişim bilgilerine göz atın.</strong>
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="row">
                            <div className="col-lg-3 ">
                                <img src={img1} />
                            </div>
                            <div className="col-lg-3">
                                <img src={img2} />
                            </div>
                            <div className="col-lg-3">
                                <img src={img3} />
                            </div>
                            <div className="col-lg-3 ">
                                <img src={img4} />
                            </div>
                            <div className="col-lg-3">
                                <img src={img5} />
                            </div>
                            <div className="col-lg-3 ">
                                <img src={img6} />
                            </div>
                            <div className="col-lg-3 ">
                                <img src={img7} />
                            </div>
                            <div className="col-lg-3">
                                <img src={img8} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

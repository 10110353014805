import React from 'react';
import { Content } from './../../comp';
import { OverviewFeatures1, OverviewFeatures2, OverviewFeatures3, OverviewFeatures4 } from './../../comp/components';

const Overview = () => {
    return (
        <>
            <Content
                content={
                    <div id="page">
                        <div className="pageBanner"></div>
                        <div className="containerOne">
                            <div className="contex">
                                <div className="row">
                                    <div className="col-md-12 text-center mt-3 mb-5">
                                        <div className="ad-title">
                                            <h3>Properties</h3>
                                        </div>
                                        <div className="title">
                                            <h1>Overview</h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 m-auto text-center mt-3 mb-5">
                                        <p>
                                            OneFrame® is a powerful enterprise application framework for all your needs.
                                            If you want to create a WebAPI or you want to create an IOS application use
                                            OneFrame®.
                                        </p>
                                        <p>
                                            We have different types of application templates build with OneFrame® like
                                            React, Vue, MVC, WebAPI, IOS, Android, etc.
                                        </p>

                                        <p>
                                            The reason why OneFrame® is powerful; we follow industry needs and evolve
                                            itself. Because we build enterprise applications with OneFrame® and support
                                            to different types of industries.
                                        </p>

                                        <p>If does that sound good, you must be looking at the live demo</p>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-md-12 mt-3 text-center">
                                                <OverviewFeatures1 backgroundColor="#eaeaea" />
                                            </div>
                                            <div className="col-md-12 mt-3 text-center">
                                                <OverviewFeatures2 backgroundColor="#d1effd" />
                                            </div>
                                            <div className="col-md-12 mt-3 text-center">
                                                <OverviewFeatures3 backgroundColor="#fdecb6" />
                                            </div>
                                            <div className="col-md-12 mt-3 text-center">
                                                <OverviewFeatures4 backgroundColor="#343a4a" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default Overview;

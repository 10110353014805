import React, { memo } from 'react';

export default memo((prop:any) => {

    return (
        <div id="widgets">
            <div className="row ">
                <div className="col-md-12">
                    <div className="overviewFeatures">
                        <div className="row">
                            <div className="col-md-12  p-0">
                                <div className="title"  style={{backgroundColor: prop.backgroundColor}}>
                                    <h5>
                                        <strong>Overview</strong>
                                    </h5>
                                </div>
                            </div>
                            <div className="col-md-12 tabContainer"  style={{backgroundColor: prop.backgroundColor}}>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-4 mb-2 mb-2">
                                        <div className="block">
                                            <h5>Codes</h5>
                                            <div className="row">
                                                <div className="col-md-12 ">
                                                    <div className="row">
                                                        <div className="col-md-6 mb-2">
                                                            <div className="chips">Web</div>
                                                        </div>
                                                        <div className="col-md-6 mb-2">
                                                            <div className="chips">Mobile</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">Backend</div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">Core</div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">Business</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-4 mb-2 mb-2">
                                        <div className="block">
                                            <h5>UI Components</h5>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6  mb-2">
                                                            <div className="chips">Web</div>
                                                        </div>
                                                        <div className="col-md-6  mb-2">
                                                            <div className="chips">Mobile</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">Data</div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">Logging</div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">Caching</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-4 mb-2 mb-2">
                                        <div className="block">
                                            <h5>Templates</h5>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6  mb-2">
                                                            <div className="chips">.Net Core</div>
                                                        </div>
                                                        <div className="col-md-6  mb-2">
                                                            <div className="chips">Android</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6  mb-2">
                                                            <div className="chips">IOS</div>
                                                        </div>
                                                        <div className="col-md-6  mb-2">
                                                            <div className="chips">React</div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">Vue</div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">MVC</div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">Hangfire</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-4 mb-2 mb-2">
                                        <div className="block">
                                            <h5>Documents</h5>
                                            <div className="row">
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">Component Features</div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">How to Guide</div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">Sample Codes</div>
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <div className="chips">FAQ</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

import React, { memo, useState, useEffect } from 'react';
import logo from './../../assets/img/oneframe-logo-disi.png';
import f from './../../assets/img/social/facebook.png';
import t from './../../assets/img/social/twitter.png';
import l from './../../assets/img/social/linkedin.png';
import y from './../../assets/img/social/youtube.png';
import { Initializer } from './../../comp/components';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


export default memo(() => {
    const { pathname } = useLocation();

    const [newPath, setNewPath] = useState<string>(pathname);

    useEffect(() => {
        window.scrollTo(0, 0);
        let element = document.getElementById('content');
        element.classList.toggle('fade-out');
    }, [newPath]);
    
    return (
        <div className="row p-0 m-0" id="footer">
            <div className="col-12 p-0">
                <Initializer></Initializer>
            </div>
            <div className="col-12 p-0">
                <div className="containerOne pt-5">
                    <div className="row">
                        <div className="col-md-3 siteMap">
                            <img src={logo} />
                        </div>
                        <div className="col-md-3  siteMap">
                            <h6>Sitemap</h6>
                            <ul className="list-unstyled text-small">
                                <li>
                                    <NavLink 
                                        id="navLink"
                                        exact={true}
                                        activeClassName="active" 
                                        to="/" onClick={(e: any) => setNewPath('/')}>
                                        Welcome
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                        id="navLink"
                                        exact={true} 
                                        activeClassName="active" 
                                        to="/overview" 
                                        onClick={(e: any) => setNewPath('/overview')}>
                                        Overview
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                        id="navLink"
                                        exact={true} 
                                        activeClassName="active" 
                                        to="/benefits" 
                                        onClick={(e: any) => setNewPath('/benefits')}>
                                        Benefits
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                        id="navLink"
                                        exact={true} 
                                        activeClassName="active" 
                                        to="/features" 
                                        onClick={(e: any) => setNewPath('/features')}>
                                        Features
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink 
                                        id="navLink"
                                        exact={true} 
                                        activeClassName="active" 
                                        to="/support-packages" 
                                        onClick={(e: any) => setNewPath('/support-packages')}>
                                        Support Packages
                                    </NavLink>
                                </li>
                                <li>
                                    <a href="https://www.kocsistem.com.tr/iletisim/" target="blank">
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3  siteMap">
                            <h6>Company</h6>
                            <ul className="list-unstyled text-small">
                                <li>
                                    <a className="text-muted" href="https://www.kocsistem.com.tr/" target="blank">
                                        KoçSistem
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3  siteMap">
                            <h6>Contact</h6>
                            <ul className="list-unstyled text-small">
                                <li>
                                    <a className="text-muted" href="mailto:ghostbusters@kocsistem.com.tr">
                                        info@kocsistem.com.tr
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-12 siteMap mt-5">
                            <div className="row ">
                                <div className="col-md-3 ">
                                    <div className="d-block mb-3 text-muted ">&copy; 2020 KoçSistem All Rights Reserved</div>
                                </div>
                                <div className="col-md-9 ">
                                    <div className="social-wrap">
                                        <div className="icon">
                                            <a href="https://www.facebook.com/KocSistem" target="blank">
                                                <img src={f} />
                                            </a>
                                        </div>
                                        <div className="icon">
                                            <a href="https://twitter.com/KocSistem" target="blank">
                                                <img src={t} />
                                            </a>
                                        </div>
                                        <div className="icon">
                                            <a href="https://www.linkedin.com/company/kocsistem" target="blank">
                                                <img src={l} />
                                            </a>
                                        </div>
                                        <div className="icon">
                                            <a
                                                href="https://www.youtube.com/channel/UCnLTgdT2JXW6T2zB73zX0Pw" target="blank">
                                                <img src={y} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

import React, { memo } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

export default memo(() => {
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const slider = (
        <div id="widgets">
            <div className="containerOne">
                <div className="comments">
                    <div className="shadowBlock"></div>
                    <div className="row">
                        <AutoplaySlider
                            organicArrows={false}
                            startup={true}
                            infinite={true}
                            transitionDelay={100}
                            play={true}
                            cancelOnInteraction={false} // should stop playing on user interaction
                            interval={6000}
                        >
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-8  m-auto">
                                        <div className="row">
                                            <div className="col-md-6 text-left">
                                                <div className="ad-title">
                                                    <h3>Users</h3>
                                                </div>
                                                <div className="title">
                                                    <h1>What Developers Say?</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 m-auto">
                                        <div className="comment">
                                            <p>OneFrame® is the best helper for developers.</p>
                                            <p>
                                                My development speed up %40 after I met with OneFrame® The system is
                                                stable so I don't think error management or response standards etc., all
                                                I need to include the OneFrame®
                                            </p>
                                            <div className="user">
                                                <div className="text">
                                                    <div className="name">Yusuf Aktürk</div>
                                                    <div className="job-title">Software Developer</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-8  m-auto">
                                        <div className="row">
                                            <div className="col-md-6 text-left">
                                                <div className="ad-title">
                                                    <h3>Users</h3>
                                                </div>
                                                <div className="title">
                                                    <h1>What Developers Say?</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 m-auto">
                                        <div className="comment">
                                            <p>OneFrame® is the best helper for developers.</p>
                                            <p>
                                                My development speed up %40 after I met with OneFrame® The system is
                                                stable so I don't think error management or response standards etc., all
                                                I need to include the OneFrame®
                                            </p>
                                            <div className="user">
                                                <div className="text">
                                                    <div className="name">Yusuf Aktürk</div>
                                                    <div className="job-title">Software Developer</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AutoplaySlider>
                    </div>
                </div>
            </div>
        </div>
    );
    return slider;
});

import React from 'react';
import { Content } from './../../comp';
const Price = () => {
    return (
        <>
            <Content
                content={
                    <div id="page">
                        <div className="pageBanner"></div>
                        <div className="containerOne">
                            <div className="contex">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12 col-sm-12  mt-3 mb-5">
                                        <div className="ad-title">
                                            <h3>Properties</h3>
                                        </div>
                                        <div className="title">
                                            <h1>Features</h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-12 col-sm-12  mt-3 mb-5">
                                        <div className="row features">
                                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                                <div className="item">
                                                    <h3>Modern & Lean Architecture</h3>
                                                    <ul>
                                                        <li>Layered approach based on best practices.</li>
                                                        <li>
                                                            Twelve - Factor App methodology & SOLID principles are
                                                            applied
                                                        </li>
                                                        <li>No redundant code elements</li>
                                                        <li>No over-engineering & unnecessary implementations</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                                <div className="item-2">
                                                    <h3>Advanced Authentication & Authorization</h3>
                                                    <ul>
                                                        <li>Tenant, user, role, permission based management</li>
                                                        <li>
                                                            Built in JWT Bearer Authentication based on .Net Identity
                                                            IdentityServer4 and KeyCloak support
                                                        </li>
                                                        <li>SAML 2.0 support</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                                <div className="item-3">
                                                    <h3>Quality & Performance Oriented DevOps Practices</h3>
                                                    <ul>
                                                        <li>
                                                            Served by automated CI/CD pipelines that runs Unit,
                                                            Integration and Load tests constantly to ensure code quality
                                                        </li>
                                                        <li>Battle tested templates used in numerous projects</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                                <div className="item-4">
                                                    <h3>Solid & Practical Data Access Layer</h3>
                                                    <ul>
                                                        <li>
                                                            EF Core Code-First implementation, scaffolding and
                                                            migrations
                                                        </li>
                                                        <li>
                                                            Support of SQL Server, PostgreSQL, Oracle, MSSQL, MySQL,
                                                            plus other popular databases
                                                        </li>
                                                        <li>Built in Repository pattern, UoW pattern implementation</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                                <div className="item-4">
                                                    <h3>Audit Logging</h3>
                                                    <ul>
                                                        <li>Automatic database Audit Support</li>
                                                        <li>API Request&Response Audit Support</li>
                                                        <li>
                                                            Multiple Data providers are supported like Redis,
                                                            Elasticsearch, NLog, SQL Server, PostgreSQL, MongoDB, etc.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                                <div className="item">
                                                    <h3>Faster Applications with Different Caching Options</h3>
                                                    <ul>
                                                        <li>In-Memory Cache support</li>
                                                        <li>Redis Distributed Cache support</li>
                                                        <li>SQL Server Cache support</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                                <div className="item-2">
                                                    <h3>Metronic based templates</h3>
                                                    <ul>
                                                        <li>ASP.Net MVC, React, Vuejs</li>
                                                        <li>Most popular & used UI theme</li>
                                                        <li>Based on Bootstrap</li>
                                                        <li>Responsive design & Multiple layout options</li>
                                                        <li>Supporting sample usage and documents</li>
                                                        <li>Material Design and Bootsrapt Support</li>
                                                        <li>
                                                            Reach Features data grid, easy to use sorting, pagination,
                                                            batch operations, support to custom functions.
                                                        </li>
                                                        <li>Supporting to Management Service and easy to requests.</li>
                                                        <li>Full UI Components ready to use (React & Vuejs)</li>
                                                        <li>Professional Support and Upgrade Version Support</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                                <div className="item-3">
                                                    <h3>Ready for Cloud & Containerization</h3>
                                                    <ul>
                                                        <li>Full Docker & Kubernetes support</li>
                                                        <li>
                                                            Tested and ran on Azure and with Linux and Windows operating
                                                            systems.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                                <div className="item-4">
                                                    <h3>Built-in UI Features</h3>
                                                    <ul>
                                                        <li>
                                                            Login with ReCaptca, 2FA (SMS, QR Code, LDAP/Active
                                                            Directory, Google, Facebook, Twitter)
                                                        </li>
                                                        <li>Forgot password</li>
                                                        <li>Sign up</li>
                                                        <li>Dynamic sidebar menu tree</li>
                                                        <li>Dashboard with various charts</li>
                                                        <li>Profile operations (information, password, photo, etc.)</li>
                                                        <li>User operation</li>
                                                        <li>Role operation</li>
                                                        <li>User and role claim management</li>
                                                        <li>Application settings</li>
                                                        <li>Report pages</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                                <div className="item">
                                                    <h3>Built-in Global Features</h3>
                                                    <ul>
                                                        <li>Auto logout with configurations</li>
                                                        <li>Light & Dark mode</li>
                                                        <li>
                                                            Multi-language
                                                            <ul style={{ margin: '0px' }}>
                                                                <li>
                                                                    All backend services (response, error, and other)
                                                                </li>
                                                                <li>Data-level support for database data</li>
                                                                <li>LTR & RTL UI support</li>
                                                            </ul>
                                                        </li>
                                                        <li>Exception handling & logging middleware</li>
                                                        <li>API communication middleware</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                                                <div className="item-2">
                                                    <h3>Other Features</h3>
                                                    <ul>
                                                        <li>Elasticsearch integration support</li>
                                                        <li>Multi-Tenancy support</li>
                                                        <li>
                                                            Hangfire Template support with built in Windows Service
                                                            setup
                                                        </li>
                                                        <li>
                                                            Notification support with different channels (SMS, E-mail,
                                                            Push Notification, Slack)
                                                        </li>
                                                        <li>FAQ System support</li>
                                                        <li>Document Management System support</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default Price;

import React from 'react';
import './web/assets/css/style.scss';
import Header from './web/comp/layout/header';
import Footer from './web/comp/layout/footer';
import { BrowserRouter } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home, Price, ContactUs, Overview, Benefits, Features } from './web/pages';
export default () => {
    return (
        <div id="layout">
            <BrowserRouter>
                <Router>
                    <Header isClass={true}></Header>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/overview" component={Overview} />
                        <Route exact path="/benefits" component={Benefits} />
                        <Route exact path="/features" component={Features} />
                        <Route exact path="/support-packages" component={Price} />
                        <Route exact path="/contact" component={ContactUs} />
                    </Switch>
                    <Footer></Footer>
                </Router>
            </BrowserRouter>
        </div>
    );
};

import React, { memo } from 'react';
import reactLogo from './../../assets/img/react.png';
import vueLogo from './../../assets/img/vue.png';
import netcore from './../../assets/img/netcore.png';
import ios from './../../assets/img/ios.png';
import android from './../../assets/img/android.png';
import flutter from './../../assets/img/flutter.png';
import megaMenuCloseIcon from './../../assets/img/mega-menu-close-icon.png';

import f from './../../assets/img/social/facebook.png';
import t from './../../assets/img/social/twitter.png';
import l from './../../assets/img/social/linkedin.png';
import y from './../../assets/img/social/youtube.png';

export default memo(() => {
    function handleNavToggle() {
        let el = document.querySelector('#nav-toggle');
        let me = document.querySelector('#megaMenu');
        el.classList.toggle('active');
        me.classList.toggle('active');
    }
    return (
        <div id="megaMenu">
            <div className="wrap">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-6">
                                <h1>All Products</h1>
                            </div>
                            <div className="col-6 text-right">
                                <a id="nav-toggle" onClick={handleNavToggle}>
                                    <img src={megaMenuCloseIcon} />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 text-center">
                        <div className="flex-container">
                            <div className="block">
                                <div className="logo">
                                    <img src={reactLogo} />
                                </div>
                                <div className="title">
                                    <h4>React</h4>
                                </div>
                                <div className="content">
                                    <ul>
                                        <li>
                                            <a 
                                                target="blank" 
                                                href="http://oneframe-livedemo-react.azurewebsites.net/">
                                                Live Demo
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="blank"
                                                href="https://oneframe-livedemo-react-docs.azurewebsites.net/?path=/story/*">
                                                Documentation
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="blank"
                                                href="https://gitlab.kocsistem.com.tr/oneframe/oneframe">
                                                Issues
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="block">
                                <div className="logo">
                                    <img src={vueLogo} />
                                </div>
                                <div className="title">
                                    <h4>Vue</h4>
                                </div>
                                <div className="content">
                                    <ul>
                                        <li>
                                            <a 
                                                target="blank" 
                                                href="http://oneframe-livedemo-vue.azurewebsites.net/">
                                                Live Demo
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="blank"
                                                href="https://oneframe.kocsistem.com.tr/docs/#/oneframe/templates/vuejs/README">
                                                Documentation
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="blank"
                                                href="https://gitlab.kocsistem.com.tr/oneframe/oneframe">
                                                Issues
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="block">
                                <div className="logo">
                                    <img src={netcore} />
                                </div>
                                <div className="title">
                                    <h4>.Net Core</h4>
                                </div>
                                <div className="content">
                                    <ul>
                                        <li>
                                            <a
                                                target="blank"
                                                href="http://oneframe-livedemo-mvc.azurewebsites.net/">
                                                Live Demo MVC
                                            </a>
                                        </li>
                                        <li>
                                            <a target="blank" href="http://oneframe-livedemo-api.azurewebsites.net">
                                                Live Demo Web API
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="blank"
                                                href="https://oneframe.kocsistem.com.tr/docs/#/oneframe/templates/web-api/README">
                                                Documentation
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="blank"
                                                href="https://gitlab.kocsistem.com.tr/oneframe/oneframe">
                                                Issues
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="block">
                                <div className="logo">
                                    <img src={ios} />
                                </div>
                                <div className="title">
                                    <h4>IOS</h4>
                                </div>
                                <div className="content">
                                    <ul>
                                        <li>
                                            <a 
                                                target="blank" 
                                                href="https://oneframe.kocsistem.com.tr/docs/#/">
                                                Documentation
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="block">
                                <div className="logo">
                                    <img src={android} />
                                </div>
                                <div className="title">
                                    <h4>Android</h4>
                                </div>
                                <div className="content">
                                    <ul>
                                        <li>
                                            <a 
                                                target="blank" 
                                                href="https://oneframe.kocsistem.com.tr/docs/#/">
                                                Documentation
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="block">
                                <div className="logo">
                                    <img src={flutter} />
                                </div>
                                <div className="title">
                                    <h4>Flutter</h4>
                                </div>
                                <div className="content">
                                <ul>
                                        <li>
                                            <a 
                                                target="blank"
                                                href="https://oneframe.kocsistem.com.tr/docs/#/">
                                                Documentation
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-lg-12 mt-5">
                        <div className="row bsPrefix">
                            <div className="col-lg-12 ref">
                                <div className="flex-container-initializer">
                                    <div className="block">INITIALIZER</div>
                                    <div className="block">
                                        <a href="https://oneframe.azurewebsites.net/">
                                            Check our Initializer application to explore different OneFrame Templates.
                                        </a>
                                    </div>
                                    <div className="block">
                                        <a href="https://oneframe.azurewebsites.net/">
                                            <Button id="menuButton" className="btn btn-light w-100">
                                                Download &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <img src={downloadicon} className="right" />
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-lg-12 mt-5">
                        <div className="row">
                            <div className="col-md-6 ">
                                <div className="d-block  text-muted ">&copy; 2020 KoçSistem All Rights Reserved</div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="d-block text-muted ">
                                    <div className="icon">
                                        <a href="https://www.facebook.com/KocSistem" target="blank">
                                            <img src={f} />
                                        </a>
                                    </div>
                                    <div className="icon">
                                        <a href="https://twitter.com/KocSistem" target="blank">
                                            <img src={t} />
                                        </a>
                                    </div>
                                    <div className="icon">
                                        <a href="https://www.linkedin.com/company/kocsistem" target="blank">
                                            <img src={l} />
                                        </a>
                                    </div>
                                    <div className="icon">
                                        <a
                                            href="https://www.youtube.com/channel/UCnLTgdT2JXW6T2zB73zX0Pw" target="blank">
                                            <img src={y} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

import React from 'react';
import i_1 from './../../../web/assets/img/benefits/icons/i_1.png';
import i_2 from './../../../web/assets/img/benefits/icons/i_2.png';
import i_3 from './../../../web/assets/img/benefits/icons/i_3.png';
import i_4 from './../../../web/assets/img/benefits/icons/i_4.png';
import { Content } from './../../comp';

const Price = () => {
    return (
        <>
            <Content
                content={
                    <div id="page">
                        <div className="pageBanner"></div>
                        <div className="containerOne">
                            <div className="contex">
                                <div className="row">
                                    <div className="col-md-12 text-center mt-3 mb-5">
                                        <div className="ad-title">
                                            <h3>Properties</h3>
                                        </div>
                                        <div className="title">
                                            <h1>Benefits</h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-5">
                                        <section className="our-webcoderskull">
                                            <ul className="row p-0 ">
                                                <li className="col-12 col-md-6 col-lg-3">
                                                    <div className="cnt-block equal-hight">
                                                        <img src={i_1} className="mb-4" />
                                                        <h5>Rapid Application Development</h5>
                                                        <ul className="follow-us clearfix">
                                                            <li>
                                                                <p>Ready-to-go packages for common tasks.</p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    Application templates for different project types.
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>Rich UI Components.</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3">
                                                    <div className="cnt-block equal-hight">
                                                        <img src={i_2} className="mb-4" />
                                                        <h5>Low Maintenance Costs</h5>
                                                        <ul className="follow-us clearfix">
                                                            <li>
                                                                <p>
                                                                    Used and battle-tested platform in numerous
                                                                    projects.
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    No unexpected bugs or problems after deployment to
                                                                    production.
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    Ability to troubleshoot & resolve problems with rich
                                                                    application metrics.
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3">
                                                    <div className="cnt-block equal-hight">
                                                        <img src={i_3} className="mb-4" />
                                                        <h5>Quality</h5>
                                                        <ul className="follow-us clearfix">
                                                            <li>
                                                                <p>Secure code with high quality standards.</p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    Components that are easy to understand and integrate
                                                                    with.
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>Code standardization with templates.</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li className="col-12 col-md-6 col-lg-3">
                                                    <div className="cnt-block equal-hight">
                                                        <img src={i_4} className="mb-4" />
                                                        <h5>Modern & Up to Date Technology</h5>
                                                        <ul className="follow-us clearfix">
                                                            <li>
                                                                <p>
                                                                    Containerized platform which is fully compatible
                                                                    with microservice archtitecture.
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>Cloud ready.</p>
                                                            </li>
                                                            <li>
                                                                <p>Continuous updates to platform.</p>
                                                            </li>
                                                            <li>
                                                                <p>Multiple platform support.</p>
                                                            </li>
                                                            <li>
                                                                <p>Multi-tenancy support.</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default Price;

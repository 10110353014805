import React, { memo } from 'react';
import mvcScreen01 from './../../../assets/img/widgets/info/oneframe-screen-mvc-01.png';
import mvcScreen02 from './../../../assets/img/widgets/info/oneframe-screen-mvc-02.png';
import mvcScreen03 from './../../../assets/img/widgets/info/oneframe-screen-mvc-03.png';
import reactScreen from './../../../assets/img/widgets/info/oneframe-screen-react.png';
import vueScreen from './../../../assets/img/widgets/info/oneframe-screen-vue.png';
import codeScreen from './../../../assets/img/widgets/info/oneframe-screen-code.png';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

export default memo(() => {
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const slider = (
        <AutoplaySlider
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={2000}
            animation="cubeAnimation"
        >
            <div className="item">
                <img src={mvcScreen01} />
            </div>
            <div className="item">
                <img src={mvcScreen03} />
            </div>
            <div className="item">
                <img src={mvcScreen02} />
            </div>
            <div className="item">
                <img src={reactScreen} />
            </div>
            <div className="item">
                <img src={vueScreen} />
            </div>
            <div className="item">
                <img src={codeScreen} />
            </div>
        </AutoplaySlider>
    );
    return (
        <div className="info" id="widgets">
            <div className="containerOne">
                <div className="row">
                    <div className="col-md-5">
                        <div className="text">
                            <div className="ad-title">
                                <h3>Product</h3>
                            </div>
                            <div className="title">
                                <h1>OneFrame®</h1>
                            </div>
                            <p>
                                We support a wide range of different application templates to suit your needs. We
                                provide templates that are based on different modern technologies like React, Vue, MVC, Web
                                API, IOS, Android and more. We follow industry trends and evolve our platform
                                accordingly to meet the new business requirements.
                            </p>
                            <p>
                                OneFrame is a battle tested application development platform that is used in many
                                different business sectors like automative, education, insurance etc. You can check our
                                reference projects and customers for more information.
                            </p>

                            <NavLink id="navLink" exact={true} activeClassName="active" to="/overview">
                                <Button variant="danger">Learn More</Button>
                            </NavLink>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="image">
                            <div className="shadow-1"></div>
                            <div className="shadow-2"></div>
                            <div className="items">{slider}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

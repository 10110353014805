import React, { memo } from 'react';
import i_1 from './../../../assets/img/widgets/initializer/icon-1.png';
import i_2 from './../../../assets/img/widgets/initializer/icon-2.png';
import i_3 from './../../../assets/img/widgets/initializer/icon-3.png';

export default memo(() => {
    return (
        <div id="widgets" className="initializer">
            <div className="containerOne">
                <div className="row">
                    <div className="col-3 text-left p-0 col-md-12">
                        <div className="title">
                            <div className="flex-box">
                                <h3>Get started with OneFrame® today.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-md-4">
                        <div className="item">
                            <img src={i_1} />
                            <a target="blank" href="http://oneframe-livedemo-mvc.azurewebsites.net/">
                                <h6>Live Demo</h6>
                            </a>
                            <p>Check our OneFrame® Template live demo.</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-md-4">
                        <div className="item">
                            <img src={i_2} />
                            <a href="/download/community-edition/latest.zip" download title='Download OneFrame® Community Template'>
                                <h6>Download</h6>
                            </a>
                            <p>Download OneFrame® Community Template.</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-md-4">
                        <div className="item">
                            <img src={i_3} />
                            <a target="blank" href="https://oneframe.kocsistem.com.tr/docs/#/">
                                <h6>Documentation</h6>
                            </a>
                            <p>Read OneFrame® documentation.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

import React from 'react';
import { Content } from './../../comp';
const Price = () => {
    return (
        <>
            <Content
                content={
                    <div id="page">
                        <div className="pageBanner"></div>
                        <div className="containerOne">
                            <div className="contex pricing">
                                <div className="row">
                                    <div className="col-md-12 text-center mt-3 mb-5">
                                        <div className="ad-title">
                                            <h3>Get Started</h3>
                                        </div>
                                        <div className="title">
                                            <h1>Support Packages</h1>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-12 m-auto text-center mt-3 mb-5">
                        <p>
                            OneFrame® is a powerful enterprise application framework for all your needs.
                            If you want to create a WebAPI or you want to create an IOS application use
                            OneFrame®.
                        </p>
                        <p>
                            We have different types of application templates build with OneFrame® like
                            React, MVC, WebAPI, IOS, Android, etc.
                        </p>

                        <p>
                            The reason why OneFrame® is powerful; we follow industry needs and evolve
                            itself. Because we build enterprise applications with OneFrame® and support
                            to different types of industries.
                        </p>

                        <p>If does that sound good, you must be looking at the live demo</p>
                    </div> */}

                                    <div className="col-lg-12 container packageIntro">
                                        <div className="row ">
                                            <div className="col-md-12 comparisontable">
                                                <ul className="row">
                                                    <li>
                                                        <div className="title">
                                                            <h5>Community</h5>
                                                        </div>
                                                        <div className="first content">
                                                            <div className="text">
                                                                <p>
                                                                    <strong>Limited Access</strong>
                                                                </p>
                                                                <p>
                                                                    <em>Free forever</em>
                                                                    <br />
                                                                    <br /> Asp.Net Core MVC Template
                                                                    <br /> Asp.Net Core API Template
                                                                </p>
                                                                <br />
                                                                <strong>Additional Templates</strong>
                                                                <p>- None -</p>
                                                                <br />
                                                                <p>Includes <strong>14</strong> OneFrame Packages</p>
                                                            </div>
                                                            <a
                                                                href="/download/community-edition/latest.zip"
                                                                download
                                                                title="Download OneFrame® Community Template"
                                                                className="link"
                                                            >
                                                              <span className="text">Download CE</span>
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">
                                                            <h5>Basic</h5>
                                                        </div>
                                                        <div className="second content">
                                                            <div className="text">
                                                                <p>
                                                                    <strong>Full Access</strong>
                                                                </p>
                                                                <p><em>12 months of access to all packages</em></p>
                                                                <p>
                                                                    Includes all kinds of updates and immediately
                                                                    available
                                                                </p>
                                                                <p>Includes Metronic Theme License</p>
                                                                <strong>Additional Templates</strong>
                                                                <p>
                                                                    React Template
                                                                    <br />
                                                                    VueJS Template
                                                                    <br />
                                                                    Rule Engine
                                                                    <br />
                                                                    FAQ Template
                                                                    <br />
                                                                    Code Generation Plug-In
                                                                </p>
                                                                <p>
                                                                    Includes <strong>50+</strong> OneFrame Packages
                                                                </p>
                                                            </div>
                                                            <a
                                                                href="https://www.kocsistem.com.tr/iletisim/"
                                                                target="blank"
                                                                className="link"
                                                            >
                                                                Contact Us
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">
                                                            <h5>Professional</h5>
                                                        </div>
                                                        <div className="third content">
                                                            <div className="text">
                                                                <p>
                                                                    <strong>
                                                                        All <em>Basic</em> features included +
                                                                    </strong>
                                                                </p>
                                                                <p>Priority Support Desk</p>
                                                                <p><strong>48H</strong> Response Time (<strong>15</strong> Incident)</p>
                                                                <br />

                                                                <strong>Additional Templates</strong>
                                                                <p>
                                                                    DMS Service
                                                                    <br />
                                                                    Notification Service
                                                                </p>
                                                            </div>
                                                            <a
                                                                href="https://www.kocsistem.com.tr/iletisim/"
                                                                target="blank"
                                                                className="link"
                                                            >
                                                                Contact Us
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">
                                                            <h5>Enterprise</h5>
                                                        </div>
                                                        <div className="fourth content">
                                                            <div className="text">
                                                                <p>
                                                                    <strong>
                                                                        All <em>Professional</em> features included +
                                                                    </strong>
                                                                </p>
                                                                <p>
                                                                    <strong>24H</strong> Response Time (
                                                                    <strong>30</strong> Incident)
                                                                </p>

                                                                <br />
                                                                <strong>Additional Templates</strong>
                                                                <p>
                                                                    Microservice Template
                                                                    <br />
                                                                    Chat Bot
                                                                </p>
                                                            </div>
                                                            <a
                                                                href="https://www.kocsistem.com.tr/iletisim/"
                                                                target="blank"
                                                                className="link"
                                                            >
                                                                Contact Us
                                                            </a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default Price;

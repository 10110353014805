import React, { memo, useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import logo from './../../assets/img/oneframe-logo.png';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default memo((props: any) => {

    const { pathname } = useLocation();

    const [newPath, setNewPath] = useState<string>(pathname);

    useEffect(() => {
        window.scrollTo(0, 0);
        let element = document.getElementById('content');
        element.classList.toggle('fade-out');
    }, [newPath]);

    function handleNavToggle() {
        let el = document.querySelector('#nav-toggle');
        let me = document.querySelector('#megaMenu');
        el.classList.toggle('active');
        me.classList.toggle('active');
    }

    return (
        <Navbar
            sticky="top"
            className={props.isClass.isClass ? 'containerOne' : ''}
            collapseOnSelect
            expand="lg"
            bg="transparent"
            variant="light"
        >
            <Navbar.Brand id="navbar-brand">
                <div className="brand-container">
                    <NavLink to="">
                        <img src={logo} />
                    </NavLink>
                    <div id="barText" className="barTextBrowser">
                        <a id="nav-toggle" onClick={handleNavToggle}>
                            <FontAwesomeIcon icon={faBars} /> &nbsp; &nbsp; All Products
                        </a>
                    </div>
                </div>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav nav-left="true" className="mr-left nav-center">
                    <div id="barText" className="barTextMoile">
                        <a id="nav-toggle" onClick={handleNavToggle}>
                            <FontAwesomeIcon icon={faBars} /> &nbsp; &nbsp; All Products
                        </a>
                    </div>
                </Nav>
                <Nav nav-right="true" className="mr-left nav-right">
                    <NavLink
                        id="navLink"
                        exact={true}
                        className="nav-link"
                        activeClassName="active"
                        to="/"
                        onClick={(e: any) => setNewPath('/')}
                    >
                        <div className="text">Welcome</div>
                        <div className="line"></div>
                    </NavLink>

                    <NavLink
                        id="navLink"
                        exact={true}
                        className="nav-link"
                        activeClassName="active"
                        to="/overview"
                        onClick={(e: any) => setNewPath('/overview')}
                    >
                        <div className="text">Overview</div>
                        <div className="line"></div>
                    </NavLink>

                    <NavLink
                        id="navLink"
                        exact={true}
                        className="nav-link"
                        activeClassName="active"
                        target="_blank"
                        to={{ pathname: "https://oneframe.kocsistem.com.tr/docs" }} 
                    >
                        <div className="text">Documentation</div>
                        <div className="line"></div>
                    </NavLink>

                    <NavLink
                        id="navLink"
                        exact={true}
                        className="nav-link"
                        activeClassName="active"
                        to="/benefits"
                        onClick={(e: any) => setNewPath('/benefits')}
                    >
                        <div className="text">Benefits</div>
                        <div className="line"></div>
                    </NavLink>

                    <NavLink
                        id="navLink"
                        exact={true}
                        className="nav-link"
                        activeClassName="active"
                        to="/features"
                        onClick={(e: any) => setNewPath('/features')}
                    >
                        <div className="text">Features</div>
                        <div className="line"></div>
                    </NavLink>

                    <NavLink
                        id="navLink"
                        exact={true}
                        className="nav-link"
                        activeClassName="active"
                        to="/support-packages"
                        onClick={(e: any) => setNewPath('/support-packages')}
                    >
                        <div className="text">Support Packages</div>
                        <div className="line"></div>
                    </NavLink>

                    <a id="navLink" className="nav-link" href="https://www.kocsistem.com.tr/iletisim/" target="blank">
                        <div className="text">Contact</div>
                        <div className="line"></div>
                    </a>
                    <a href="/download/community-edition/latest.zip" download title='Download OneFrame® Community Template' className="nav-link-btn">
                        <span className="text">Download CE</span>
                    </a>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
});

import React, { memo } from 'react';
import { Navbar, MegaMenu } from '../components';

export default memo((props: any) => {
    window.onscroll = function() {
        scrollFunction();
    };
    function scrollFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.getElementById('stickyDiv').style.height = '72px';
            document.getElementById('header').style.padding = '5px 0px';
        } else {
            document.getElementById('stickyDiv').style.height = '0px';
            document.getElementById('header').style.padding = '15px 0px';
        }
    }

    return (
        <div id="header">
            <MegaMenu></MegaMenu>
            <div id="stickyDiv"></div>
            <div className="containerOne">
                <Navbar isClass={props}></Navbar>
            </div>
        </div>
    );
});

import React, { memo } from 'react';
import logo_1 from './../../../assets/img/widgets/trusted/l-1.png';
import logo_2 from './../../../assets/img/widgets/trusted/l-2.png';
import logo_3 from './../../../assets/img/widgets/trusted/l-3.png';
import logo_7 from './../../../assets/img/widgets/trusted/l-7.png';
import logo_5 from './../../../assets/img/widgets/trusted/l-5.png';
import logo_6 from './../../../assets/img/widgets/trusted/l-6.png';

export default memo(() => {
    const opacity = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6];

    let set1 = [logo_1, logo_2]


    const randomOpacity = () => {
        const random = Math.floor(Math.random() * opacity.length);
        return random;
    };

    randomOpacity();

    return (
        <div id="widgets">
            <div className="containerOne ">
                <div className="trusted-by">
                    <div className="shadowBlock"></div>
                    <div className="wrap">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="item">
                                    <div className="ad-title">
                                        <h3>Business</h3>
                                    </div>
                                    <div className="title">
                                        <h1>Trusted By</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-4 childitem">
                                        <h1>150 +</h1>
                                        <p>Developers</p>
                                    </div>
                                    <div className="col-md-4 childitem">
                                        <h1>10 +</h1>
                                        <p>Customer</p>
                                    </div>
                                    <div className="col-md-4 childitem">
                                        <h1>30 +</h1>
                                        <p>Projects</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="logo-container">
                                    <div className="logo">
                                        <div className="row">
                                            <div
                                                className="col-4"
                                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.' + randomOpacity() + ')' }}
                                            >
                                                <img src={set1[0]}  />
                                            </div>
                                            <div
                                                className="col-4"
                                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.' + randomOpacity() + ')' }}
                                            >
                                                <img src={logo_2} />
                                            </div>
                                            <div
                                                className="col-4"
                                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.' + randomOpacity() + ')' }}
                                            >
                                                <img src={logo_3} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="logo">
                                        <div className="row">
                                            <div
                                                className="col-4"
                                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.' + randomOpacity() + ')' }}
                                            >
                                                <img src={logo_7} />
                                            </div>
                                            <div
                                                className="col-4"
                                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.' + randomOpacity() + ')' }}
                                            >
                                                <img src={logo_5} />
                                            </div>
                                            <div
                                                className="col-4"
                                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.' + randomOpacity() + ')' }}
                                            >
                                                <img src={logo_6} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

import React, { memo } from 'react';

export default memo(() => {
    return (
        <div className="subSection dark-theme">
            <div className="containerOne">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <h4>Can't find what you're looking for?</h4>
                        <p>If your question is not answered above, we're happy to help.</p>
                        <a
                            className="btn btn-white btn-round"
                            href="/cdn-cgi/l/email-protection#7a181e0e1b09113a1d171b131654191517"
                        >
                            Submit a Support Request
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
});

import React, { memo } from 'react';

export default memo((prop: any) => {
    return (
        <div id="widgets">
            <div className="row ">
                <div className="col-md-12">
                    <div className="overviewFeatures">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className="title" style={{ backgroundColor: prop.backgroundColor, color: '#fff' }}>
                                    <h5>
                                        <strong>OneFrame® Packages</strong>
                                    </h5>
                                </div>
                            </div>
                            <div className="col-md-12  tabContainer" style={{ backgroundColor: prop.backgroundColor }}>
                                <div className="row">
                                    <div className="col-md-4 mt-3 ">
                                        <div className="block">
                                            <h5 style={{color:"#fff"}}>Server</h5>
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <div className="chips">
                                                        <h6>Authentication</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className="chips">
                                                        <h6>Caching</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <div className="chips">
                                                        <h6>Dependency Injection</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <div className="chips">
                                                        <h6>Log Management</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className="chips">
                                                        <h6>Searching</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className="chips">
                                                        <h6>Configuration</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className="chips">
                                                        <h6>Validation</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className="chips">
                                                        <h6>Data</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 mt-3">
                                                    <div className="chips">
                                                        <h6>Error Handling</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <div className="chips">
                                                        <h6>Mapper</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className="chips">
                                                        <h6>LDAP</h6>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className="chips">
                                                        <h6>Localization</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                    <div className="block">
                                        <h5 style={{color:"#fff"}}>Mobile</h5>
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <div className="chips">
                                                    <h6>Networking</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div className="chips">
                                                    <h6>Logging</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div className="chips">
                                                    <h6>SignalR</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div className="chips">
                                                    <h6>Maps</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div className="chips">
                                                    <h6>sqLite</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div className="chips">
                                                    <h6>Viperize</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div className="chips">
                                                    <h6>DMS</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div className="chips">
                                                    <h6>Commons</h6>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                    <div className="block">
                                        <h5 style={{color:"#fff"}}>Web</h5>
                                        <div className="row ">
                                            <div className="col-md-12 mt-3">
                                                <div className="chips">
                                                    <h6>Metronic Themes</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="chips">
                                                    <h6>API Manager</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="chips">
                                                    <h6>React UI Components</h6>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="chips">
                                                    <h6>React State Manager</h6>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

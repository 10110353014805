import React, { memo } from 'react';
import i_1 from './../../../assets/img/widgets/features/i_1.png';
import i_2 from './../../../assets/img/widgets/features/i_2.png';
import i_3 from './../../../assets/img/widgets/features/i_3.png';
import i_4 from './../../../assets/img/widgets/features/i_4.png';
import i_5 from './../../../assets/img/widgets/features/i_5.png';
import i_6 from './../../../assets/img/widgets/features/i_6.png';
import i_7 from './../../../assets/img/widgets/features/i_7.png';
import i_8 from './../../../assets/img/widgets/features/i_8.png';

export default memo(() => {
    return (
        <div id="widgets">
            <div className="containerOne features">
                <div className="row">
                    <div className="col-md-12 text-center mt-3 mb-5">
                        <div className="ad-title">
                            <h3>Properties</h3>
                        </div>
                        <div className="title">
                            <h1>Features </h1>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="item">
                                    <div className="head">
                                        <div className="icon">
                                            <img src={i_1} />
                                        </div>
                                        <div className="title">
                                            <h5>Solid Architecture</h5>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p>Layered, clean source code built on a strong base framework.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="item">
                                    <div className="head">
                                        <div className="icon">
                                            <img src={i_2} />
                                        </div>
                                        <div className="title">
                                            <h5>Multi-Tenancy</h5>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p>
                                            OneFrame® has built in multi-tenancy support for your multiple customer
                                            management needs.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="item">
                                    <div className="head">
                                        <div className="icon">
                                            <img src={i_3} />
                                        </div>
                                        <div className="title">
                                            <h5>Cloud-Ready</h5>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p>
                                            All features of OneFrame® are ready for the cloud hosting and compatible
                                            with DevOps processes. Write your app once and host it on which platform you
                                            want.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="item">
                                    <div className="head">
                                        <div className="icon">
                                            <img src={i_4} />
                                        </div>
                                        <div className="title">
                                            <h5>High-level standards</h5>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p>
                                            High code quality is essential in OneFrame®. Code review and code quality
                                            tools like SonarQube and CAST are used to ensure high code quality.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="item">
                                    <div className="head">
                                        <div className="icon">
                                            <img src={i_5} />
                                        </div>
                                        <div className="title">
                                            <h5>Robust</h5>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p>
                                            All OneFrame® templates are robust with OneFrame® packages support. We
                                            provide packages for Authentication Management, Data Management, Cache
                                            Management, Security etc.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="item">
                                    <div className="head">
                                        <div className="icon">
                                            <img src={i_6} />
                                        </div>
                                        <div className="title">
                                            <h5>Database Options</h5>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p>
                                            OneFrame® supports many different database platforms. For example; MSSQL,
                                            PostgreSQL, Oracle, etc.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="item">
                                    <div className="head">
                                        <div className="icon">
                                            <img src={i_7} />
                                        </div>
                                        <div className="title">
                                            <h5>Always up-to-date</h5>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p>OneFrame® features always follow industry needs and evolve itself.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="item">
                                    <div className="head">
                                        <div className="icon">
                                            <img src={i_8} />
                                        </div>
                                        <div className="title">
                                            <h5>Many platforms supported</h5>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p>
                                            OneFrame® templates are compatible with all OS such as; Windows, Linux,
                                            MacOs, Android and IOS.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-md-12 text-center mb-3 mt-3">
                        <div className="row">
                            <div className="col-md-12">
                                <Button variant="danger">... More</Button>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
});

import React from 'react';
import { Banner, Info, Features, TrustedBy, Comments } from './../../comp/components';
import { Content } from './../../comp';
const Home = () => {
    return (
        <>
            <Content
                content={
                    <div className="row">
                        <div className="col-lg-12">
                            <Banner></Banner>
                        </div>
                        <div className="col-lg-12">
                            <Info></Info>
                        </div>
                        <div className="col-lg-12">
                            <Features></Features>
                        </div>
                        <div className="col-lg-12">
                            <TrustedBy></TrustedBy>
                        </div>
                        <div className="col-lg-12 text-center">
                            <Comments></Comments>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default Home;
